import Config from "../config.js";
import { handle401 } from "./utils";
export const ApiComunicator = (urlTail, info, method,isAnDownload = false) => {
  return new Promise((resolve, reject) => {
    let url = Config.endpoint + urlTail;
    const params = {
      credentials: "include",
      method: method,
      mode: "cors",
      headers: new Headers({
        "Content-Type": "application/json;charset=utf-8",
      }),
    };
    if (info != null) {
      params.body = JSON.stringify(info);
    }

    fetch(url, params)
      .then((response) => {
        // console.log("API response",response);
        if (response.ok) {
          if(isAnDownload === true || response.status === 204){
            return response;
          }else{
            return response.json();
          }
        } else {
          //Se vencio el token / No autorizado
          if (response.status === 401) {
            handle401();
            reject(response);
          } else {
            throw response;
          }
        }
      })
      .then(resolve)
      .catch((error) => {
        // console.log("API error",typeof error,error);
        let genericError = {errors:[{detail:"Ha ocurrido un error al hacer la petición"}]};
        if (error.status === 401) handle401();
        if (error.status === 404) return genericError;
        if (typeof error.json !== 'function') return genericError;

        return error.json()
      })
      .then(reject);
  });
};
