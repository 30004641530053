import React from "react";
import { ApiComunicator } from "./ApiComunicator";
import fileDownload from "react-file-download";
import { STATES } from "./globals";
import { Badge } from "reactstrap";

/**
 * Maneja el error 401 (no autorizado). Se solicita un re-logeo
 */
export const handle401 = () => {
  localStorage.clear();
  localStorage.setItem("relogin", true);
  //Guardo el path al que intentó acceder para redireccionar al re-logearse
  localStorage.setItem("redirect_relogin", window.document.location.pathname);
  window.location.href = "/login";
};
/**
 * Retorna una lista de párrafos con un mensaje simple en cada linea
 * @param {array} messages lista de mensajes a mostrar
 */
export const printSimpleMessages = (messages) => {
  let rows = [];
  messages.forEach((msg) => {
    rows.push(
      <p key={"p"} style={{ marginBottom: 0 }}>
        {msg}
      </p>
    );
  });
  return rows;
};
/**
 *
 * @param {integer} n Número a aplicar el left pad
 * @param {integer} width Largo final del número con left pad
 * @param {char} z Número de relleno para el pad, default 0
 *
 * Ejemplo: lpad(5,4) -> "0005"
 */
export const lpad = (n, width, z) => {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};



export const isEmpty = (value) => value === null || value === "" || value === 0 || value.length === 0;

/**
 * Formatea un número en formato moneda
 * ej: MoneyFormat.format(1500) // "1.500,00"
 */
export const MoneyFormat = new Intl.NumberFormat("de-DE", {maximumFractionDigits:2,minimumFractionDigits:2});

/**
 * Imprime el label en el formato 12u (2x6u)
 * @param {int} un_x_bulto
 * @param {int} unidades
 */
export const labelUnidadesXBulto = (un_x_bulto, unidades) => {
  if(un_x_bulto === 0) un_x_bulto = 1;
  let cantBultos = unidades / un_x_bulto;
  //no debería dar decimal, pero por las dudas si da decimal le acoto la cantidad de decimales
  if (unidades % un_x_bulto !== 0) cantBultos = cantBultos.toFixed(2);

  let labelCantidad = unidades + "u (" + cantBultos + "x" + un_x_bulto + "u)"; //el campo cantBultos1 es "cantidad de unidades por bulto"

  return labelCantidad;
};

export const imprimirArchivo = (url, file_name) => {
  let isAnDownload = true;
  ApiComunicator(url, null, "GET", isAnDownload)
    .then((data) => {
      return data.blob();
    })
    .then((blob) => {
      fileDownload(blob, file_name);
    });
};
/**
 * Formatea a URL la data
 * @param {Object} data 
 */
export const encodeQueryData = (data) => {
  const ret = [];
  for (let d in data)
    if(!isEmpty(data[d])) //No concatenar campos vacíos
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}
// ============================================================
// generar labels de estado para mostrar
export const labelEstadoPreparacion = (idEstadoPreparacion, text) => {
  let colorBadgeEstado = "";
  switch (idEstadoPreparacion) {
    case STATES.PREP_HEADER.ABIERTA:
    case STATES.PREP_HEADER.EN_PROCESO:
      colorBadgeEstado = "badge badge-pill badge-warning";
      break;
    case STATES.PREP_HEADER.CERRADA_INCOMPLETA:
      colorBadgeEstado = "badge badge-pill badge-success";
      break;
    case STATES.PREP_HEADER.CERRADA:
      colorBadgeEstado = "badge badge-pill badge-secondary";
      break;
    case STATES.PREP_HEADER.CANCELADA:
      colorBadgeEstado = "badge badge-pill badge-danger";
      break;
    default:
      colorBadgeEstado = "badge badge-pill badge-dark";
      break;
  }

  return <Badge className={colorBadgeEstado}>{text}</Badge>;
};
export const labelEstadoPreparacionPedido = (idEstadoPrepPedido, text) => {
  let colorBadgeEstado = "";
  switch (idEstadoPrepPedido) {
    case STATES.PREP_PEDIDO.CERRADO_COMPLETO:
      colorBadgeEstado = "badge badge-pill badge-secondary";
      break;
    case STATES.PREP_PEDIDO.CERRADO_PARCIAL:
      colorBadgeEstado = "badge badge-pill badge-success";
      break;
    case STATES.PREP_PEDIDO.PENDIENTE:
      colorBadgeEstado = "badge badge-pill badge-warning";
      break;
    default:
      colorBadgeEstado = "badge badge-pill badge-dark";
      break;
  }

  return <Badge className={colorBadgeEstado}>{text}</Badge>;
};
export const labelEstadoPedido = (idEstadoPedido, text) => {
  let colorBadgeEstado = "";
  switch (idEstadoPedido) {
    case STATES.PEDIDO.SIN_PREPARAR:
      colorBadgeEstado = "badge badge-pill badge-secondary";
      break;
    case STATES.PEDIDO.EN_PREPARACION:
      colorBadgeEstado = "badge badge-pill badge-warning";
      break;
    case STATES.PEDIDO.PREPARADO_PARCIAL:
      colorBadgeEstado = "badge badge-pill badge-success";
      break;
    case STATES.PEDIDO.CERRADO:
      colorBadgeEstado = "badge badge-pill badge-dark";
      break;
    case STATES.PEDIDO.CANCELADO:
      colorBadgeEstado = "badge badge-pill badge-danger";
      break;
    case STATES.PEDIDO.DCHO_DIRECTO:
      colorBadgeEstado = "badge badge-pill badge-success";
      break;
    case STATES.PEDIDO.FUERA_SISTEMA:
      colorBadgeEstado = "badge badge-pill badge-dark";
      break;
  }

  return <Badge className={colorBadgeEstado}>{text}</Badge>;
};
export const labelEstadoDespacho = (idEstadoDespacho, text) => {
  let colorBadgeEstado = "";
  switch (idEstadoDespacho) {
    case STATES.DCHO_HEADER.CARGADO:
      colorBadgeEstado = "badge badge-pill badge-warning";
      break;
    case STATES.DCHO_HEADER.FINALIZADO:
      colorBadgeEstado = "badge badge-pill badge-dark";
      break;
    case STATES.DCHO_HEADER.CANCELADO:
      colorBadgeEstado = "badge badge-pill badge-danger";
      break;
  }

  return <Badge className={colorBadgeEstado}>{text}</Badge>;
};
export const labelEstadoDespachoPedido = (idEstadoDchoPedido, text) => {
  let colorBadgeEstado = "";
  switch (idEstadoDchoPedido) {
    case STATES.DCHO_PEDIDOS.CARGADO:
      colorBadgeEstado = "badge badge-pill badge-secondary";
      break;
    case STATES.DCHO_PEDIDOS.EN_CAMINO:
    case STATES.DCHO_PEDIDOS.PEND_RETIRO:
      colorBadgeEstado = "badge badge-pill badge-warning";
      break;
    case STATES.DCHO_PEDIDOS.RETIRADO:
    case STATES.DCHO_PEDIDOS.ENTREGADO:
      colorBadgeEstado = "badge badge-pill badge-dark";
      break;
    case STATES.DCHO_PEDIDOS.DEVUELTO:
      colorBadgeEstado = "badge badge-pill badge-danger";
      break;
  }

  return <Badge className={colorBadgeEstado}>{text}</Badge>;
};


export const labelEstadoOrdenCompra = (idTipoEstado, text) => {

  let colorBadgeEstado = "";
  switch (idTipoEstado) {
    case STATES.ORDEN_COMPRA.GENERADA:
      colorBadgeEstado = "badge badge-pill badge-success";
      break;
    case STATES.ORDEN_COMPRA.FINALIZADA:
      colorBadgeEstado = "badge badge-pill badge-dark";
      break;
    case STATES.ORDEN_COMPRA.ANULADA:
      colorBadgeEstado = "badge badge-pill badge-danger";
      break;
  }

  return <Badge className={colorBadgeEstado}>{text}</Badge>;
};
export const labelEstadoInformeRecepcion = (idTipoEstado) => {
  let colorBadgeEstado = "";
  let text = "";
  switch (idTipoEstado) {
    case 'A':
      colorBadgeEstado = "badge badge-pill badge-success";
      text = "Abierto";
      break;
    case 'C':
      colorBadgeEstado = "badge badge-pill badge-dark";
      text = "Cerrado";
      break;
    case 'X':
      colorBadgeEstado = "badge badge-pill badge-danger";
      text = "Anulado";
      break;
  }

  return <Badge className={colorBadgeEstado}>{text}</Badge>;
};

export const labelEstadoParteProductivo = (estado, text) => {
  let colorBadgeEstado = "";
  switch (estado) {
    case STATES.PARTE_PRODUCTIVO.GENERADO:
      text = "Generado";
      colorBadgeEstado = "badge badge-pill badge-success";
      break;
    case STATES.PARTE_PRODUCTIVO.ANULADO:
      text = "Anulado";
      colorBadgeEstado = "badge badge-pill badge-danger";
      break;
    }
  return <Badge color={colorBadgeEstado}>{text}</Badge>;
};
