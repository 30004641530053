export const STATES = {
  PEDIDO:{
    SIN_PREPARAR:1,
    EN_PREPARACION:2,
    PREPARADO_PARCIAL:3,
    CERRADO:4,
    CANCELADO:5,
    DCHO_DIRECTO:6,
    FUERA_SISTEMA:7
  },
  PREP_HEADER:{
    ABIERTA:1,
    EN_PROCESO:2,
    CERRADA_INCOMPLETA:3,
    CERRADA:4,
    CANCELADA:5
  },
  PREP_PEDIDO:{
    PENDIENTE:1,
    EN_PREPARACION:2,
    CERRADO_PARCIAL:3,
    CERRADO_SIN_PICK:4,
    CERRADO_COMPLETO:5
  },
  DCHO_HEADER:{
    CARGADO:1,
    FINALIZADO:2,
    CANCELADO:3
  },
  DCHO_PEDIDOS:{
    CARGADO:1,
    EN_CAMINO:2,
    ENTREGADO:3,
    DEVUELTO:4,
    PEND_RETIRO:5,
    RETIRADO:6
  },
  ORDEN_COMPRA:{
    GENERADA:1,
    FINALIZADA: 2,
    ANULADA:3,
  },
  PARTE_PRODUCTIVO:{
    GENERADO:'A',
    ANULADO:'C',
  },
}
