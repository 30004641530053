import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';

//API Helper
import {ApiComunicator} from '../../helpers/ApiComunicator';

const loading = () => <div className="animated fadeIn pt-3 text-center">Cargando...</div>;

// Containers
const AppLayout = React.lazy(() => import('../../containers/AppLayout/AppLayout'));
const QRLayout = React.lazy(() => import('../../containers/QR/QRLayout'));

// Pages
const LoginContainer = React.lazy(() => import('../../containers/Login/LoginContainer'));


class App extends Component {
//---------------------------------------------------------------------------
  componentDidMount() {
      let pathname = window.document.location.pathname;
      if(pathname !== "/login"){
          //Si tiene guardados datos de una sesión, chequeo el token
          if(this.isAuthenticated()){
              this.chequearToken()
          }
      }
  }
  componentWillUnmount(){
    localStorage.clear();
  }
//---------------------------------------------------------------------------
  //Despues de verificar el login, traigo el token y lo guardo
  
  chequearToken = () => {
    let url = "/auth/checktoken";
    ApiComunicator(url, null, 'GET')
    .then(response => {
      if (response.status !== "ok")
      {
          //Borra SOLAMENTE si NO está OK la validación del token
          //Sino sigue noraml
          localStorage.clear()
          window.document.location.pathname = "/login" 
      }
    })
    .catch( error => {
      //si devuelve error, eliminar datos si no valida Token
      localStorage.clear()
      window.document.location.pathname = "/login"  
    })

  }
//---------------------------------------------------------------------------
  isAuthenticated = () => {
    return localStorage.getItem('user_id') !== null && localStorage.getItem('user') !== null;
  }
//---------------------------------------------------------------------------
// onLogout: Lo heredan los componentes como propiedad para desloguear.
//---------------------------------------------------------------------------
  onLogout = () => {
    localStorage.clear();
    return(<Redirect to="/login" /> )
  }
//---------------------------------------------------------------------------
  PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        this.isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />
    )}
    />
  )
//---------------------------------------------------------------------------
  // RESPUESTA
  render() {
    return (
      <Router>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <LoginContainer {...props}/>} />
              <Route path="/qr" name="QR test" render={props => <QRLayout {...props}/>} />

              <this.PrivateRoute path="/" isAuthenticated={this.isAuthenticated} component={props => <AppLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </Router>
    );

  }
}

export default App;
